<template>
  <v-app>
    <v-row no-gutters class="fill-height">
      <v-col>
        <section id="content">
          <nav>
            <v-app-bar app elevate-on-scroll color="white" height="100">
              <v-row no-gutters class="flex justify-center">
                <v-img
                  contain
                  max-width="100"
                  src="@/assets/logo/logo_kedasbeauty.png"
                ></v-img>
              </v-row>
              <v-app-bar-title>
                <v-avatar tile> </v-avatar>
                <!-- <v-btn color="white" dark class="ml-2" large to="/">
                  <span class="font-weight-bold purple--text text-lowercase"
                    >kedasbeautymember.com</span
                  >
                </v-btn> -->
              </v-app-bar-title>
            </v-app-bar>
          </nav>

          <!-- <v-row no-gutters>
            <v-carousel
              cycle
              hide-delimiter-background
              :show-arrows="false"
              height="auto"
              interval="10000"
            >
              <v-carousel-item v-for="(banner, i) in banners" :key="i">
                <v-img
                  contain
                  width="100%"
                  :src="banner.img"
                  :aspect-ratio="21.6 / 9"
                >
                  <v-app-bar flat color="transparent">
                    <v-app-bar-title>
                      <v-avatar max-height="100" tile>
                        <v-img
                          contain
                          src="@/assets/logo/logo_kedasbeauty.png"
                        ></v-img>
                      </v-avatar>
                      <v-btn color="white" dark class="ml-2" small to="/">
                        <span
                          class="font-weight-bold purple--text text-lowercase"
                          >kedasbeautymember.com</span
                        >
                      </v-btn>
                    </v-app-bar-title>
                  </v-app-bar>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-row> -->

          <v-row no-gutters>
            <v-container>
              <v-row no-gutters class="py-5">
                <v-col>
                  <h3
                    class="text-3xl font-bold text-[#6867AC] drop-shadow-md text-center"
                  >
                    Best Seller Products
                  </h3>
                  <p class="mt-5 text-[#6867AC] font-medium text-center">
                    Produk Kedas Beauty diformulasikan oleh para ahli untuk
                    memenuhi kebutuhan kulit kamu agar nampak cerah dan sehat.
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="6"
                  md="6"
                  lg="3"
                  xl="3"
                  class="d-flex justify-center"
                  v-for="(p, i) in products"
                  :key="i"
                >
                  <v-card
                    elevation="0"
                    class="hover:drop-shadow-md transition ease-in-out"
                    rounded="lg"
                    min-width="125"
                    width="45vw"
                    max-width="500"
                    to="/daftar-member"
                  >
                    <v-img class="ma-2" :src="p.img"></v-img>
                    <v-card-actions>
                      <v-btn block depressed to="/daftar-member" color="accent">
                        <span>beli</span>
                        <v-icon right>mdi-shopping</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-row no-gutters>
            <v-container>
              <h1
                class="text-center font-bold text-3xl text-[#6867AC] drop-shadow-md mt-5"
              >
                Join Reseller
              </h1>
              <v-row class="my-5">
                <v-col
                  class="d-flex justify-center my-n1"
                  v-for="(btn, i) in buttons"
                  :key="i"
                >
                  <v-btn
                    block
                    dark
                    depressed
                    :href="btn.url"
                    :color="btn.color"
                    target="_blank"
                  >
                    <v-icon large left>mdi-face-agent</v-icon>
                    {{ btn.caption }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-btn
                    block
                    dark
                    depressed
                    href="https://verifikasi-produk.kedasbeauty.id"
                    color="#8b5cf6"
                    target="_blank"
                  >
                    Verifikasi Produk Asli/Palsu
                  </v-btn>
              </v-row>
            </v-container>
          </v-row>
        </section>
        <footer
          class="bg-blue-200 text-[#6867AC] pb-24 pt-16 text-center px-16"
        >
          <div class="flex flex-col md:flex-row justify-center w-full">
            <div class="w-auto text-left p-8">
              <p class="font-bold text-xl">Kedas Beauty</p>
              <p>
                Copyright &copy; {{ new Date().getFullYear() }} Kedas Beauty
                owned by PT. Mahapurna Mandiri Utama.
              </p>
              <p>All Rights Reserved.</p>
            </div>
            <div class="w-auto text-left p-8">
              <p class="font-bold text-xl">Info</p>
              <!-- Vue Link -->
              <a href="https://wa.me/6282353333621" target="_blank">
                <span
                  class="text-[#6867AC] hover:text-indigo-400 transition ease-in-out cursor-pointer"
                >
                  Join Reseller Kami
                </span>
              </a>
              <br />
              <a href="https://wa.me/6282353333621" target="_blank">
                <span
                  class="text-[#6867AC] hover:text-indigo-400 transition ease-in-out cursor-pointer"
                >
                  Hubungi Kami
                </span>
              </a>
              <p class="font-bold text-xl mt-4">Follow Kami</p>
              <div class="flex flex-row space-x-4">
                <a
                  href="https://www.youtube.com/c/CandraDewiFikaOfficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="cursor-pointer"
                >
                  <i
                    class="fa-brands fa-youtube fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
                  ></i>
                </a>
                <a
                  href="https://www.instagram.com/kedas_beautyofficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="cursor-pointer"
                >
                  <i
                    class="fa-brands fa-instagram fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
                  ></i>
                </a>
                <a
                  href="https://www.tiktok.com/@kedasbeautypusat"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="cursor-pointer"
                >
                  <i
                    class="fa-brands fa-tiktok fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
                  ></i>
                  <!-- <img :src="tiktokIcon" /> -->
                </a>
                <a
                  href="https://facebook.com/kedasbeuty.co.id"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="cursor-pointer"
                >
                  <i
                    class="fa-brands fa-facebook fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </footer>
        <!-- <v-footer dark padless style="width: 100%">
          <v-card
            flat
            tile
            class="kb-footer white--text text-center pa-5"
            width="100%"
          >
            <v-card-title class="white--text pt-0">
              <v-col cols="12" md="6" lg="6" xl="6">
                <h3 class="text-center">
                  Get connected with us on our social networks!
                </h3>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-tooltip bottom v-for="(sn, i) in social_networks" :key="i">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      outlined
                      x-large
                      :color="sn.color"
                      :href="sn.url"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      target="_blank"
                      class="mx-3"
                    >
                      <v-icon>{{ sn.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ sn.tooltip_text }}</span>
                </v-tooltip>
              </v-col>

              <v-spacer></v-spacer>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="white--text">
              {{ new Date().getFullYear() }} —
              <strong>PT. MAHAPURNA MANDIRI UTAMA</strong>
            </v-card-text>
          </v-card>
        </v-footer> -->
      </v-col>
    </v-row>

    <v-dialog v-model="pop_up" max-width="800">
      <v-card rounded="lg">
        <v-img
          src="@/assets/promo/pop_up_02.png"
          @click="redirect"
          contain
        ></v-img>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Home",
  components: {
    // HomeNav,
  },
  data() {
    return {
      buttons: [],
      social_networks: [
        {
          icon: "mdi-instagram",
          url: "https://www.instagram.com/candradewimaharani_/",
          tooltip_text: "Instagram",
          color: "pink accent-3",
        },
        {
          icon: "mdi-music-circle-outline",
          url: "https://www.tiktok.com/@ceokedasbeauty",
          tooltip_text: "TikTok",
          color: "black",
        },
        {
          icon: "mdi-youtube",
          url: "https://www.youtube.com/c/CandraDewiFikaOfficial",
          tooltip_text: "YouTube",
          color: "red",
        },
      ],
      pop_up: false,
      products: [
        {
          img: require("@/assets/products/gold_jelly.png"),
          name: "Gold Jelly",
        },
        {
          img: require("@/assets/products/soap.png"),
          name: "Brightening Soap",
        },
        {
          img: require("@/assets/products/serum.png"),
          name: "Brightening Body Serum",
        },
        {
          img: require("@/assets/products/scrub.png"),
          name: "Brightening Body Serum",
        },
      ],
      banners: [
        { img: require("@/assets/landing_page/banner-1.jpg") },
        { img: require("@/assets/landing_page/banner-02.jpg") },
        { img: require("@/assets/landing_page/banner-03.jpg") },
        { img: require("@/assets/landing_page/banner-04.jpg") },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchEvents", "fetchEventImages"]),
    async getButtonsState() {
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/buttons`)
        .then((response) => {
          this.buttons = response.data;
          console.log(this.buttons);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    redirect() {
      window.open("https://kedasbeautymember.com/promo?event_id=1&prize_id=2");
    },
    async showPopUp() {
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/events/1`)
        .then((response) => {
          if (response.data.is_active == 1) {
            this.pop_up = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["allEvents", "allEventImages"]),
  },
  created() {
    this.getButtonsState();
    this.fetchEvents();
    this.fetchEventImages();
    this.showPopUp();
  },
};
</script>
